export var NODE_TREE = function (items, mode, role,patientRegistable) {
    if (typeof items != 'object') {
        return items;
    }
    let filterNode = items.filter((nd) => {
            // if (nd.to == '/pages/patient/manage') {
            //     return patientRegistable;
            // } else {
                return nd.mode.includes(mode) && nd.roles.includes(role);
            // }
        }
    );

    for (let node of filterNode) {
        if (typeof node.items != 'undefined') {
            node.items = NODE_TREE(node.items, mode, role, patientRegistable);
        }
    }
    return filterNode;
}



export var _NAV = function () {
    const ALL = ['ADMINISTRATOR', 'REGISTRATOR','PROVIDER','DOCTOR','MAINTENANCE','ALL'];
    return  [{
        _name: 'CSidebarNav',
        _children: [
            {
                _name: 'CSidebarNavDropdown',
                name: 'お知らせ',
                route: '/pages/base',
                icon: 'cil-envelope-open',
                mode: ['prod','dev'],
                roles: ALL,
                items: [
                    {
                        name: 'お知らせ一覧',
                        to: '/pages/notice/list',
                        // icon: 'cil-envelope-open',
                        mode: ['prod','dev'],
                        roles: ALL,
                    }, {
                        name: 'お知らせ登録',
                        to: '/pages/notice/regist',
                        // icon: 'cil-envelope-open',
                        mode: ['prod', 'dev'],
                        roles: ['ADMINISTRATOR', 'REGISTRATOR'],
                    }
                ]
            }, {
                _name: 'CSidebarNavDropdown',
                name: '患者情報',
                route: '/pages/patient',
                icon: 'cil-people',
                roles: ['ADMINISTRATOR', 'REGISTRATOR','PROVIDER','DOCTOR'],
                mode: ['prod','dev'],
                items: [
                    {
                        name: '一覧',
                        to: '/pages/patient/list',
                        mode: ['prod', 'dev'],
                        roles: ['ADMINISTRATOR', 'REGISTRATOR','PROVIDER','DOCTOR'],
                    },
                    {
                        name: '登録・編集',
                        to: '/pages/patient/manage',
                        mode: ['prod', 'dev'],
                        roles: ['ADMINISTRATOR', 'REGISTRATOR','PROVIDER','DOCTOR'],
                    },
                    {
                        name: 'アーカイブ一覧',
                        to: '/pages/patient/archive',
                        mode: ['prod', 'dev'],
                        roles: ['ADMINISTRATOR', 'REGISTRATOR','PROVIDER','DOCTOR'],
                    }
                ]
            },{
                _name: 'CSidebarNavDropdown',
                name: '機器情報',
                route: '/pages/device',
                icon: 'cil-devices',
                mode: ['prod','dev'],
                roles: ['ADMINISTRATOR', 'REGISTRATOR','MAINTENANCE','PROVIDER'],
                items: [
                    {
                        name: '機器一覧',
                        to: '/pages/device/list',
                        // icon: 'cil-speech',
                        mode: ['prod', 'dev'],
                        roles: ['ADMINISTRATOR', 'REGISTRATOR','MAINTENANCE','PROVIDER'],
                    },
                    {
                        name: '警報情報',
                        to: '/pages/device/healthinfo',
                        // icon: 'cil-speech',
                        mode: ['prod', 'dev'],
                        roles: ['ADMINISTRATOR', 'REGISTRATOR','MAINTENANCE','PROVIDER'],
                    },
                    {
                        name: 'ファームウェア管理',
                        to: '/pages/device/firmwaremanage',
                        // icon: 'cil-speech',
                        mode: ['prod', 'dev'],
                        roles: ['ADMINISTRATOR'],
                    }
                ]
            },
            {
                _name: 'CSidebarNavItem',
                name: 'QRコード発行',
                to: '/pages/qrcode',
                icon: 'cil-qr-code',
                mode: ['prod', 'dev'],
                roles: ['ADMINISTRATOR', 'REGISTRATOR','MAINTENANCE','PROVIDER'],
            },
            // {
            //     _name: 'CSidebarNavItem',
            //     name: 'ユーザー情報',
            //     to: '/pages/userInfo',
            //     icon: 'cil-user',
            //     mode: ['prod', 'dev'],
            //     roles: ALL,
            // }
            ,{
                _name: 'CSidebarNavDropdown',
                name: '統計情報',
                route: '/pages/statistics',
                icon: 'cil-monitor',
                mode: ['prod','dev'],
                roles:['ADMINISTRATOR', 'REGISTRATOR'],
                items: [
                    {
                        name: '酸素濃縮装置運転状況',
                        to: '/pages/statistics/devicesummary',
                        // icon: 'cil-speech',
                        mode: ['prod', 'dev'],
                        roles: ['ADMINISTRATOR', 'REGISTRATOR'],
                    },
                    {
                        name: 'アクセスログ',
                        to: '/pages/statistics/accesslog',
                        // icon: 'cil-speech',
                        mode: ['prod', 'dev'],
                        roles: ['ADMINISTRATOR'],
                    }
                ]
            },{
                _name: 'CSidebarNavDropdown',
                name: 'ID登録・編集・削除',
                route: '/pages/regists',
                icon: 'cil-plus',
                mode: ['prod','dev'],
                roles: ['ADMINISTRATOR', 'REGISTRATOR'],
                items: [
                    {
                        name: 'ユーザー管理',
                        to: '/pages/regist/userManage',
                        // icon: 'cil-speech',
                        mode: ['prod', 'dev'],
                        roles: ['ADMINISTRATOR', 'REGISTRATOR'],
                    },
                    {
                        name: '医療機関管理',
                        to: '/pages/regist/hospitalManage',
                        // icon: 'cil-speech',
                        mode: ['prod', 'dev'],
                        roles: ['ADMINISTRATOR', 'REGISTRATOR'],
                    },
                    {
                        name: '販売店管理',
                        to: '/pages/regist/agencyManage',
                        // icon: 'cil-speech',
                        mode: ['prod', 'dev'],
                        roles: ['ADMINISTRATOR', 'REGISTRATOR'],
                    },
                    {
                        name: 'ID発行管理',
                        to: '/pages/regist/IdManage',
                        // icon: 'cil-speech',
                        mode: ['prod', 'dev'],
                        roles: ['ADMINISTRATOR', 'REGISTRATOR'],
                    }
                ]
            },{
                _name: 'CSidebarNavItem',
                name: 'PatientCharts',
                to: '/pages/PatientCharts',
                icon: 'cil-chart-pie',
                mode: ['dev'],
                roles: ALL,
            },{
                _name: 'CSidebarNavItem',
                name: 'OxChartPage',
                to: '/pages/OxChartPage',
                icon: 'cil-chart-pie',
                mode: ['dev'],
                roles: ALL,
            },{
                _name: 'CSidebarNavItem',
                name: 'PatientCharts1',
                to: '/pages/PatientCharts1',
                icon: 'cil-chart-pie',
                mode: ['dev'],
                roles: ALL,
            },{
                _name: 'CSidebarNavItem',
                name: 'PatientCharts_wijmo',
                to: '/pages/PatientCharts_wijmo',
                icon: 'cil-chart-pie',
                mode: ['dev'],
                roles: ALL,
            },{
                _name: 'CSidebarNavItem',
                name: 'VueTable2Test',
                to: '/pages/VueTable2Test',
                icon: 'cil-chart-pie',
                mode: ['dev'],
                roles: ALL,
            },{
                _name: 'CSidebarNavTitle',
                _children: ['Theme'],
                mode: ['dev'],
                roles: ALL,
            },
            {
                _name: 'CSidebarNavItem',
                name: 'Dashboard',
                to: '/pages/dashboard',
                icon: 'cil-speedometer',
                badge: {
                    color: 'primary',
                    text: 'NEW'
                },
                mode: ['dev'],
                roles: ALL,
            },
            {
                _name: 'CSidebarNavTitle',
                _children: ['Theme'],
                mode: ['dev'],
                roles: ALL,
            },
            {
                _name: 'CSidebarNavItem',
                name: 'Colors',
                to: '/pages/theme/colors',
                icon: 'cil-drop',
                mode: ['dev'],
                roles: ALL
            },
            {
                _name: 'CSidebarNavItem',
                name: 'Typography',
                to: '/pages/theme/typography',
                icon: 'cil-pencil',
                mode: ['dev'],
                roles: ALL
            },
            {
                _name: 'CSidebarNavTitle',
                _children: ['Components'],
                mode: ['dev'],
                roles: ALL
            },
            {
                _name: 'CSidebarNavDropdown',
                name: 'Base',
                route: '/pages/base',
                icon: 'cil-puzzle',
                mode: ['dev'],
                roles: ALL,
                items: [
                    {
                        name: 'Breadcrumbs',
                        to: '/pages/base/breadcrumbs',
                        icon: 'cil-puzzle',
                        mode: ['dev'],
                        roles: ALL,
                    },
                    {
                        name: 'Cards',
                        to: '/pages/base/cards',
                        icon: 'cil-puzzle',
                        mode: ['dev'],
                        roles: ALL,
                    },
                    {
                        name: 'Carousels',
                        to: '/pages/base/carousels',
                        icon: 'cil-puzzle',
                        mode: ['dev'],
                        roles: ALL,
                    },
                    {
                        name: 'Collapses',
                        to: '/pages/base/collapses',
                        icon: 'cil-puzzle',
                        mode: ['dev'],
                        roles: ALL,
                    },
                    {
                        name: 'Forms',
                        to: '/pages/base/forms',
                        icon: 'cil-puzzle',
                        mode: ['dev'],
                        roles: ALL,
                    },
                    {
                        name: 'Jumbotrons',
                        to: '/pages/base/jumbotrons',
                        icon: 'cil-puzzle',
                        mode: ['dev'],
                        roles: ALL,
                    },
                    {
                        name: 'List Groups',
                        to: '/pages/base/list-groups',
                        icon: 'cil-puzzle',
                        mode: ['dev'],
                        roles: ALL,
                    },
                    {
                        name: 'Navs',
                        to: '/pages/base/navs',
                        icon: 'cil-puzzle',
                        mode: ['dev'],
                        roles: ALL,
                    },
                    {
                        name: 'Navbars',
                        to: '/pages/base/navbars',
                        icon: 'cil-puzzle',
                        mode: ['dev'],
                        roles: ALL,
                    },
                    {
                        name: 'Paginations',
                        to: '/pages/base/paginations',
                        icon: 'cil-puzzle',
                        mode: ['dev'],
                        roles: ALL,
                    },
                    {
                        name: 'Popovers',
                        to: '/pages/base/popovers',
                        icon: 'cil-puzzle',
                        mode: ['dev'],
                        roles: ALL,
                    },
                    {
                        name: 'Progress Bars',
                        to: '/pages/base/progress-bars',
                        icon: 'cil-puzzle',
                        mode: ['dev'],
                        roles: ALL,
                    },
                    {
                        name: 'Switches',
                        to: '/pages/base/switches',
                        icon: 'cil-puzzle',
                        mode: ['dev'],
                        roles: ALL,
                    },
                    {
                        name: 'Tables',
                        to: '/pages/base/tables',
                        icon: 'cil-puzzle',
                        mode: ['dev'],
                        roles: ALL,
                    },
                    {
                        name: 'Tabs',
                        to: '/pages/base/tabs',
                        icon: 'cil-puzzle',
                        mode: ['dev'],
                        roles: ALL,
                    },
                    {
                        name: 'Tooltips',
                        to: '/pages/base/tooltips',
                        icon: 'cil-puzzle',
                        mode: ['dev'],
                        roles: ALL,
                    }
                ]
            },
            {
                _name: 'CSidebarNavDropdown',
                name: 'Buttons',
                route: '/pages/buttons',
                icon: 'cil-cursor',
                mode: ['dev'],
                roles: ALL,
                items: [
                    {
                        name: 'Buttons',
                        to: '/pages/buttons/standard-buttons',
                        icon: 'cil-cursor',
                        mode: ['dev'],
                        roles: ALL,
                    },
                    {
                        name: 'Button Dropdowns',
                        to: '/pages/buttons/dropdowns',
                        icon: 'cil-cursor',
                        mode: ['dev'],
                        roles: ALL,
                    },
                    {
                        name: 'Button Groups',
                        to: '/pages/buttons/button-groups',
                        icon: 'cil-cursor',
                        mode: ['dev'],
                        roles: ALL,
                    },
                    {
                        name: 'Brand Buttons',
                        to: '/pages/buttons/brand-buttons',
                        icon: 'cil-cursor',
                        mode: ['dev'],
                        roles: ALL,
                    }
                ]
            },
            {
                _name: 'CSidebarNavItem',
                name: 'Charts',
                to: '/pages/charts',
                icon: 'cil-chart-pie',
                mode: ['dev'],
                roles: ALL,
            },
            {
                _name: 'CSidebarNavDropdown',
                name: 'Icons',
                route: '/pages/icons',
                icon: 'cil-star',
                mode: ['dev'],
                roles: ALL,
                items: [
                    {
                        name: 'CoreUI Icons',
                        to: '/pages/icons/coreui-icons',
                        icon: 'cil-star',
                        badge: {
                            color: 'info',
                            text: 'NEW'
                        },
                        mode: ['dev'],
                        roles: ALL,
                    },
                    {
                        name: 'Brands',
                        to: '/pages/icons/brands',
                        icon: 'cil-star',
                        mode: ['dev'],
                        roles: ALL,
                    },
                    {
                        name: 'Flags',
                        to: '/pages/icons/flags',
                        icon: 'cil-star',
                        mode: ['dev'],
                        roles: ALL,
                    }
                ]
            },
            {
                _name: 'CSidebarNavDropdown',
                name: 'Notifications',
                route: '/pages/notifications',
                icon: 'cil-bell',
                mode: ['dev'],
                roles: ALL,
                items: [
                    {
                        name: 'Alerts',
                        to: '/pages/notifications/alerts',
                        icon: 'cil-bell',
                        mode: ['dev'],
                        roles: ALL,
                    },
                    {
                        name: 'Badges',
                        to: '/pages/notifications/badges',
                        icon: 'cil-bell',
                        mode: ['dev'],
                        roles: ALL,
                    },
                    {
                        name: 'Modals',
                        to: '/pages/notifications/modals',
                        icon: 'cil-bell',
                        mode: ['dev'],
                        roles: ALL,
                    }
                ]
            },
            {
                _name: 'CSidebarNavItem',
                name: 'Widgets',
                to: '/pages/widgets',
                icon: 'cil-calculator',
                mode: ['dev'],
                roles: ALL,
                badge: {
                    color: 'primary',
                    text: 'NEW',
                    shape: 'pill',
                    mode: ['dev'],
                    roles: ALL,
                }
            },
            {
                _name: 'CSidebarNavDivider',
                _class: 'm-2',
                mode: ['dev'],
                roles: ALL,
            },
            {
                _name: 'CSidebarNavTitle',
                _children: ['Extras'],
                mode: ['dev'],
                roles: ALL,
            },
            {
                _name: 'CSidebarNavDropdown',
                name: 'Pages',
                route: '/',
                icon: 'cil-star',
                mode: ['dev'],
                roles: ALL,
                items: [
                    {
                        name: 'Login',
                        to: '/login',
                        icon: 'cil-star',
                        mode: ['dev'],
                        roles: ALL,
                    },
                    {
                        name: 'Register',
                        to: '/register',
                        icon: 'cil-star',
                        mode: ['dev'],
                        roles: ALL,
                    },
                    {
                        name: 'Error 404',
                        to: '/404',
                        icon: 'cil-star',
                        mode: ['dev'],
                        roles: ALL,
                    },
                    {
                        name: 'Error 500',
                        to: '/500',
                        icon: 'cil-star',
                        mode: ['dev'],
                        roles: ALL,
                    },{
                        name: 'PatientSummaryWide',
                        to: '/PatientSummaryWide',
                        icon: 'cil-star',
                        mode: ['dev'],
                        roles: ALL,
                    },{
                        name: 'PatientSummaryPrint',
                        to: '/PatientSummaryPrint',
                        icon: 'cil-star',
                        mode: ['dev'],
                        roles: ALL,
                    }
                ]
            },
            {
                _name: 'CSidebarNavItem',
                name: 'Download CoreUI',
                href: 'http://coreui.io/vue/',
                icon: {name: 'cil-cloud-download', class: 'text-white'},
                _class: 'bg-success text-white',
                target: '_blank',
                mode: ['dev'],
                roles: ALL,
            },
            {
                _name: 'CSidebarNavItem',
                name: 'Try CoreUI PRO',
                href: 'http://coreui.io/pro/vue/',
                icon: {name: 'cil-layers', class: 'text-white'},
                _class: 'bg-danger text-white',
                target: '_blank',
                mode: ['dev'],
                roles: ALL,
            }
        ]
    }];
}


